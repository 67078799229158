exports.components = {
  "component---src-pages-404-mdx": () => import("./../../../src/pages/404.mdx" /* webpackChunkName: "component---src-pages-404-mdx" */),
  "component---src-pages-about-mdx": () => import("./../../../src/pages/about.mdx" /* webpackChunkName: "component---src-pages-about-mdx" */),
  "component---src-pages-archive-bnw-garden-mdx": () => import("./../../../src/pages/archive/bnw-garden.mdx" /* webpackChunkName: "component---src-pages-archive-bnw-garden-mdx" */),
  "component---src-pages-archive-burnt-trees-mdx": () => import("./../../../src/pages/archive/burnt-trees.mdx" /* webpackChunkName: "component---src-pages-archive-burnt-trees-mdx" */),
  "component---src-pages-archive-burullus-island-mdx": () => import("./../../../src/pages/archive/burullus-island.mdx" /* webpackChunkName: "component---src-pages-archive-burullus-island-mdx" */),
  "component---src-pages-archive-desert-octopus-mdx": () => import("./../../../src/pages/archive/desert-octopus.mdx" /* webpackChunkName: "component---src-pages-archive-desert-octopus-mdx" */),
  "component---src-pages-archive-egypt-1928-mdx": () => import("./../../../src/pages/archive/egypt-1928.mdx" /* webpackChunkName: "component---src-pages-archive-egypt-1928-mdx" */),
  "component---src-pages-archive-flip-your-eye-mdx": () => import("./../../../src/pages/archive/flip-your-eye.mdx" /* webpackChunkName: "component---src-pages-archive-flip-your-eye-mdx" */),
  "component---src-pages-archive-hal-9000-generative-art-mdx": () => import("./../../../src/pages/archive/hal9000-generative-art.mdx" /* webpackChunkName: "component---src-pages-archive-hal-9000-generative-art-mdx" */),
  "component---src-pages-archive-index-js": () => import("./../../../src/pages/archive/index.js" /* webpackChunkName: "component---src-pages-archive-index-js" */),
  "component---src-pages-archive-joy-division-generative-art-mdx": () => import("./../../../src/pages/archive/joy-division-generative-art.mdx" /* webpackChunkName: "component---src-pages-archive-joy-division-generative-art-mdx" */),
  "component---src-pages-archive-kids-stories-mdx": () => import("./../../../src/pages/archive/kids-stories.mdx" /* webpackChunkName: "component---src-pages-archive-kids-stories-mdx" */),
  "component---src-pages-archive-made-from-tiny-boxes-mdx": () => import("./../../../src/pages/archive/made-from-tiny-boxes.mdx" /* webpackChunkName: "component---src-pages-archive-made-from-tiny-boxes-mdx" */),
  "component---src-pages-archive-neon-night-mdx": () => import("./../../../src/pages/archive/neon-night.mdx" /* webpackChunkName: "component---src-pages-archive-neon-night-mdx" */),
  "component---src-pages-archive-nge-space-odyssey-parallels-mdx": () => import("./../../../src/pages/archive/nge-space-odyssey-parallels.mdx" /* webpackChunkName: "component---src-pages-archive-nge-space-odyssey-parallels-mdx" */),
  "component---src-pages-archive-notes-mdx": () => import("./../../../src/pages/archive/notes.mdx" /* webpackChunkName: "component---src-pages-archive-notes-mdx" */),
  "component---src-pages-archive-on-some-faraway-beach-mdx": () => import("./../../../src/pages/archive/on-some-faraway-beach.mdx" /* webpackChunkName: "component---src-pages-archive-on-some-faraway-beach-mdx" */),
  "component---src-pages-archive-parasite-mdx": () => import("./../../../src/pages/archive/parasite.mdx" /* webpackChunkName: "component---src-pages-archive-parasite-mdx" */),
  "component---src-pages-archive-poor-easter-mdx": () => import("./../../../src/pages/archive/poor-easter.mdx" /* webpackChunkName: "component---src-pages-archive-poor-easter-mdx" */),
  "component---src-pages-archive-red-room-generative-art-mdx": () => import("./../../../src/pages/archive/red-room-generative-art.mdx" /* webpackChunkName: "component---src-pages-archive-red-room-generative-art-mdx" */),
  "component---src-pages-archive-soy-cuba-mdx": () => import("./../../../src/pages/archive/soy-cuba.mdx" /* webpackChunkName: "component---src-pages-archive-soy-cuba-mdx" */),
  "component---src-pages-archive-the-office-mdx": () => import("./../../../src/pages/archive/the-office.mdx" /* webpackChunkName: "component---src-pages-archive-the-office-mdx" */),
  "component---src-pages-archive-window-xp-palette-mdx": () => import("./../../../src/pages/archive/window-xp-palette.mdx" /* webpackChunkName: "component---src-pages-archive-window-xp-palette-mdx" */),
  "component---src-pages-archive-zero-decibel-qarun-lake-mdx": () => import("./../../../src/pages/archive/zero-decibel-qarun-lake.mdx" /* webpackChunkName: "component---src-pages-archive-zero-decibel-qarun-lake-mdx" */),
  "component---src-pages-index-mdx": () => import("./../../../src/pages/index.mdx" /* webpackChunkName: "component---src-pages-index-mdx" */),
  "component---src-pages-intro-js": () => import("./../../../src/pages/intro.js" /* webpackChunkName: "component---src-pages-intro-js" */),
  "component---src-pages-notes-dexposure-js": () => import("./../../../src/pages/notes/dexposure.js" /* webpackChunkName: "component---src-pages-notes-dexposure-js" */),
  "component---src-pages-notes-index-mdx": () => import("./../../../src/pages/notes/index.mdx" /* webpackChunkName: "component---src-pages-notes-index-mdx" */),
  "component---src-pages-readings-mdx": () => import("./../../../src/pages/readings.mdx" /* webpackChunkName: "component---src-pages-readings-mdx" */),
  "component---src-pages-writings-cra-runtime-vs-build-time-envs-mdx": () => import("./../../../src/pages/writings/cra-runtime-vs-build-time-envs.mdx" /* webpackChunkName: "component---src-pages-writings-cra-runtime-vs-build-time-envs-mdx" */),
  "component---src-pages-writings-index-js": () => import("./../../../src/pages/writings/index.js" /* webpackChunkName: "component---src-pages-writings-index-js" */),
  "component---src-pages-writings-pattern-matching-example-in-ts-mdx": () => import("./../../../src/pages/writings/pattern-matching-example-in-ts.mdx" /* webpackChunkName: "component---src-pages-writings-pattern-matching-example-in-ts-mdx" */),
  "component---src-pages-writings-theme-expirement-by-uselayouteffect-mdx": () => import("./../../../src/pages/writings/theme-expirement-by-uselayouteffect.mdx" /* webpackChunkName: "component---src-pages-writings-theme-expirement-by-uselayouteffect-mdx" */)
}

