import { Text } from 'theme-ui';
import { GatsbyImage } from 'gatsby-plugin-image';
import { graphql } from 'gatsby';
import theme from "../../../../src/gatsby-plugin-theme-ui";
import { Tiles, Container, List, Banner, Card, CardWrapper } from "../../../../src/blocks";
import { Cards } from "../../../../src/components/card";
import Intro from "../../../../src/pages/intro";
import * as React from 'react';
export default {
  Text,
  GatsbyImage,
  graphql,
  theme,
  Tiles,
  Container,
  List,
  Banner,
  Card,
  CardWrapper,
  Cards,
  Intro,
  React
};